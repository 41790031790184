import {
  ReactNode, useEffect,
} from 'react';
import router from 'next/router';
import { useApplicationState } from 'lib/contexts/ApplicationState';
import { goToLogin, goToSignup } from 'lib/redirect';

interface Props {
  children: ReactNode;
  userRequired?: boolean;
  fallback?: ReactNode;
}

export const AwaitUserAuth = ({ children, userRequired, fallback }: Props) => {
  const { isAuthed, currentUser } = useApplicationState();

  useEffect(() => {
    if (isAuthed && !currentUser?.id && userRequired) {
      goToLogin(currentUser, router, router.asPath);
      goToSignup(currentUser, router);
    }
  }, [isAuthed, currentUser?.id, userRequired]);

  if (!isAuthed || (!currentUser && userRequired)) {
    if (fallback) {
      return <>{fallback}</>;
    }

    return null;
  }

  return <>{children}</>;
};
