import Link from 'next/link';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { TikTokIcon } from 'elements/TikTokIcon';
import XIcon from '@mui/icons-material/X';

const getCurrentYear = () => new Date().getFullYear();

export const Footer = () => (
  <footer className="bg-gray-500 px-4 md:px-6 py-4 text-white relative">
    <div>
      <div className="flex justify-evenly sm:justify-start gap-x-2 sm:gap-x-4 font-bold pb-2 text-sm sm:text-base">
        <Link href="/about">
          About
        </Link>
        <Link href="/plans">
          Plans
        </Link>
        <Link href="/contact">
          Contact
        </Link>
        <a
          href="https://merch.gondola.cc/"
          target="_blank"
          rel="noreferrer"
        >
          Merch
        </a>
        <Link href="/downloads">
          Downloads
        </Link>
        <Link href="/jobs?users=ongondola~227" className="hidden sm:block">
          Careers
        </Link>
        <a
          href="https://help.gondola.cc"
          target="_blank"
          rel="noreferrer"
        >
          Help
        </a>

      </div>
      <div>
        <div className="flex justify-center pt-2 md:pt-6 lg:pt-0">
          <a
            href="https://twitter.com/ongondola"
            target="_blank"
            rel="noreferrer"
            className="px-2"
            aria-label="gondola twitter"
          >
            <XIcon />
          </a>
          <a
            href="https://instagram.com/ongondola"
            target="_blank"
            rel="noreferrer"
            className="px-2"
            aria-label="gondola instagram"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.tiktok.com/@ongondola?lang=en"
            target="_blank"
            rel="noreferrer"
            className="px-2"
            aria-label="gondola tiktok"
          >
            <TikTokIcon />
          </a>
          <a
            href="https://www.youtube.com/channel/UCE04UIfH0P4Ku6-2z9OHndg"
            target="_blank"
            rel="noreferrer"
            className="px-2"
            aria-label="gondola youtube"
          >
            <YouTubeIcon />
          </a>
          <a
            href="https://www.facebook.com/ongondola"
            target="_blank"
            rel="noreferrer"
            className="px-2"
            aria-label="gondola facebook"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.pinterest.com/ongondola/_saved/"
            target="_blank"
            rel="noreferrer"
            aria-label="gondola pinterest"
          >
            <PinterestIcon />
          </a>
        </div>
      </div>
      <div className="absolute top-4 right-6 invisible sm:visible">
        <Link href="/" aria-label="gondola home">
          <img alt="logo" src="/Logo_White.png" loading="lazy" width={24} height={24} />
        </Link>
      </div>
    </div>
    <div className="font-medium text-center md:text-right pt-4">
      <Link href="/terms" className="pr-2 underline">

        Terms of Service

      </Link>
      <Link href="/privacy" className="pr-2 underline">

        Privacy Policy

      </Link>
      ©
      {' '}
      {getCurrentYear()}
      {' '}
      Gondola Inc.
    </div>
  </footer>
);
