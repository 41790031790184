import { orderBy, toNumber } from 'lodash';
import { modelToSlug } from 'lib/utils/modelSlugUtils';
import { getAvatarOrPlaceholder, imagePostUrl } from 'lib/utils/imageUtils';
import { Url } from 'next/dist/shared/lib/router/router';
import { TAG_TYPE_IDS } from 'lib/constants';
import { truncate, buildJobPath } from 'lib/helpers';
import {
  SearchResult, PostSearchResultData, TagSearchResultData,
  UserSearchResultData, SearchResultResponse, RoleSearchResultData,
  JobSearchResultData, LocationSearchResultData,
} from 'lib/types/globalSearch';
import { User } from 'lib/types/users';

const getTagPageUrl = (result: TagSearchResultData): Url => {
  const getTagTypePrefix = (tagTypeId: number) => {
    switch (tagTypeId) {
    case TAG_TYPE_IDS.tag:
      return 'tags';
    case TAG_TYPE_IDS.location:
      return 'locations';
    case TAG_TYPE_IDS.event:
      return 'events';
    default:
      return 'tags';
    }
  };
  const modelSlug = modelToSlug(result, 'value');
  const tagTypePrefix = getTagTypePrefix(result.tagTypeId);
  return {
    pathname: '/explore',
    query: { [tagTypePrefix]: modelSlug },
  };
};

export const getUserPageUrl = (id: number, username: string, termsAcknowledged?: boolean): Url => {
  if (termsAcknowledged) {
    return {
      pathname: '/[profileuser]',
      query: { profileuser: username },
    };
  }

  return {
    pathname: '/explore',
    query: { users: `${username}~${id}` },
  };
};

const formatUserResult = (result: UserSearchResultData): SearchResult => ({
  id: result.id,
  imgUrl: getAvatarOrPlaceholder(result.avatarUrl, result.id),
  label: result.username,
  subLabel: result.name,
  modelName: 'users',
  pageUrl: getUserPageUrl(result.id, result.username, result.termsAcknowledged),
  relevancyScore: result.score,
});

export const formatPostResult = (result: PostSearchResultData): SearchResult => ({
  id: result.id,
  imgUrl: imagePostUrl(result.thumbnailUrl, { width: 80 }),
  label: result.account,
  subLabel: truncate(result.caption, 200),
  modelName: 'posts',
  pageUrl: {
    pathname: '/posts/[postId]',
    query: { postId: result.id },
  },
  relevancyScore: result.score,
});

const formatTagResult = (result: TagSearchResultData): SearchResult => ({
  id: result.id,
  imgUrl: '',
  label: result.value,
  subLabel: result.tagType,
  tagTypeId: result.tagTypeId, // This should be lower case from the server
  modelName: 'tagValues',
  pageUrl: getTagPageUrl(result),
  relevancyScore: result.score,
});

const formatRoleResult = (result: RoleSearchResultData): SearchResult => ({
  id: result.id,
  imgUrl: '',
  label: result.name,
  subLabel: 'role',
  modelName: 'role',
  pageUrl: {
    pathname: '/creators',
    query: { role: modelToSlug(result, 'name') },
  },
  relevancyScore: result.score,
});

const formatJobResult = (result: JobSearchResultData): SearchResult => ({
  id: result.id,
  imgUrl: '',
  label: result.name,
  subLabel: result.account,
  modelName: 'jobs',
  pageUrl: buildJobPath({
    id: toNumber(result.id), account: { username: result.username } as User, name: result.name,
  }),
  relevancyScore: result.score,
});

const formatLocationResult = (result: LocationSearchResultData): SearchResult => {
  const locationName = result.name.split(',')[0];
  return {
    id: result.id,
    imgUrl: '',
    label: result.name,
    subLabel: `${result.count}+ creators`,
    modelName: 'locations',
    pageUrl: {
      pathname: '/creators',
      query: { near: `${locationName}~${result.x},${result.y}` },
    },
    relevancyScore: result.score,
  };
};

export const formatResults = (searchResults: SearchResultResponse): SearchResult[] => {
  const users = searchResults.users?.map((user) => formatUserResult(user));
  const posts = searchResults.posts?.map((post) => formatPostResult(post));
  const tags = searchResults.tagValues?.map((tag) => formatTagResult(tag));
  const roles = searchResults.roles?.map((role) => formatRoleResult(role));
  const jobs = searchResults.jobs?.map((job) => formatJobResult(job));
  const locations = searchResults.locations?.map((location) => formatLocationResult(location));

  return orderBy([...users, ...posts, ...tags, ...roles, ...jobs, ...locations], ['relevancyScore'], ['desc']);
};

export const instagramUrlToId = (url: string) => {
  const regex = /(?:\/p\/|\/reel\/)/;
  const match = url.match(regex);
  return match ? url.split(match[0])[1].split('?')[0] : undefined;
};
