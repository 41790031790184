/**
 * This file should only contain constants so it can be imported in the frontend and backend
 */

/**
 * Standard error message text. This helps us keep error messages consistent across the app.
 */
export const ErrorMsg = {
  RESOURCE_ALREADY_EXISTS: 'That resource already exists',
  UNEXPECTED_ERROR: 'An unexpected error occurred',
  PLATFORM_NOT_SUPPORTED: 'Platform not supported',
  COULD_NOT_FIND_OR_CREATE_POST_WITH_URL: 'Could not find or create post with url',
  FAILED_TO_SCRAPE_POST_WITH_URL: 'Failed to scrape post with url',
  FAILED_TO_SCRAPE_POST: 'Failed to scrape post',
  INVALID_URL: 'Invalid url',
  COULD_NOT_FIND_CHANNEL_FOR_USERNAME: 'Could not find a channel for that username',
  INVALID_QUERY: 'Invalid query',
  USER_WAS_NOT_FOUND: 'User was not found',
  CODE_IS_INVALID_OR_EXPIRED: 'Sorry that code is invalid or expired!',
};
