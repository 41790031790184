import { isNil } from 'lodash';
import { Milestone } from 'lib/types/index';
import { abbreviateNum } from 'lib/helpers';

// The GCS image bucket where all PRODUCTION images go
const PROD_GCS_IMG_BUCKET = 'https://storage.googleapis.com/gondola-images/production/';
// The legacy ImageKit domain that uses digitalocean as a source
export const IMAGEKIT_URL = 'https://img.gondola.cc/';
// web-assets folder
export const WEB_ASSETS_URL = `${PROD_GCS_IMG_BUCKET}web-assets/`;
// email-assets folder
const EMAIL_ASSETS_URL = `${PROD_GCS_IMG_BUCKET}email-assets/`;
// for resumes
export const IMPORT_ASSETS_URL = 'https://storage.googleapis.com/gondola-imports/';

export const PRIVATE_CREDIT_AVATAR = `${WEB_ASSETS_URL}private-avatar.png`;

/** The default width of the images we display in post cards */
export const POST_CARD_IMG_WIDTH = 300;

export const imageAvatarUrl = (
  avatarUrl: string, diameter: number | undefined = 64, isRound: boolean | undefined = false,
) => {
  if (avatarUrl && avatarUrl.includes('/avatars/')) {
    const avatarParts = avatarUrl.split('/avatars/');
    const avatarId = avatarParts[1];
    const circleConfig = isRound ? ',r-max' : '';
    return `${IMAGEKIT_URL}tr:w-${diameter},h-${diameter},fo-auto${circleConfig}/avatars/${avatarId}`;
  }
  return avatarUrl;
};

interface ImageOptions {
  width?: number;
  height?: number;
  isDead?: boolean;
}

// TODO(Jordan): Eventually we can migrate this to next/image
export const imagePostUrl = (postThumbnailUrl: string, options?: ImageOptions) => {
  if (!postThumbnailUrl) return '';

  const { width, height, isDead } = options || {};

  const imgParams = `tr:w-${width || ''},h-${height || ''},fo-auto${isDead ? ',bl-5' : ''}`;
  return `${IMAGEKIT_URL}${imgParams}/${postThumbnailUrl}`;
};

//TODO(Jordan): Migrate the rest to Imagekit
export const imageUrl = (url?: string) => {
  if (url && url.includes(PROD_GCS_IMG_BUCKET)) {
    try {
      const imgUrlParts = url.split(PROD_GCS_IMG_BUCKET);
      const imgFilename = imgUrlParts[1];

      return `${IMAGEKIT_URL}${imgFilename}`;
    } catch (e) {
      // If anything fails, just use the original url
      return url;
    }
  }
  return url;
};

export const webAssetUrl = (path: string) => imageUrl(`${WEB_ASSETS_URL}${path}`);

export const emailAssetUrl = (path: string) => imageUrl(`${EMAIL_ASSETS_URL}${path}`);

export const replayAssetUrl = (path: string) => {
  const bucket = `${PROD_GCS_IMG_BUCKET}replay2023/`;
  return imageUrl(`${bucket}${path}`);
};

export const getAvatarOrPlaceholder = (avatarUrl?: string, userId?: number) => {
  const cleanUserId = userId || Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));
  return (isNil(avatarUrl)
    ? `/avatars/avatar-${(cleanUserId % 4) + 1}.png` : avatarUrl);
};

export const getAvatarUrlForCredit = (userId: number, avatarUrl?: string, isHidden = false) => {
  if (isHidden) {
    return PRIVATE_CREDIT_AVATAR;
  }
  if (!avatarUrl) {
    return getAvatarOrPlaceholder(avatarUrl, userId);
  }
  return imageAvatarUrl(avatarUrl);
};

export const milestoneAssetUrl = (ms: Milestone) => webAssetUrl(`milestones-svg/milestone-${ms.type}-${abbreviateNum(ms.threshold, 0)}.svg`);
