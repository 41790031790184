import React from 'react';

interface Props {
  className?: string;
}

export const DesktopLogo = ({
  className,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1688 500"
    className={className}
  >
    <g>
      <path
        fill="#F76F4A"
        d="M40.26 0h7.81v-49.47h-9.63v32.45L12.63-49.54H5.16V0h9.63v-31.75L40.26 0zm37.39 0c3.73 0 7.09-.59 10.09-1.78s5.57-2.86 7.71-5.02c2.14-2.17 3.78-4.77 4.92-7.82 1.13-3.04 1.7-6.45 1.7-10.22 0-3.39-.51-6.59-1.53-9.59s-2.56-5.62-4.61-7.85c-2.04-2.23-4.59-4-7.64-5.3-3.04-1.31-6.59-1.96-10.64-1.96h-18V0h18zM91.4-18.35c-.65 2-1.59 3.73-2.83 5.2-1.23 1.46-2.76 2.61-4.6 3.45-1.84.84-3.94 1.26-6.32 1.26h-8.37v-32.65h8.37c2.33 0 4.41.39 6.25 1.18 1.84.79 3.38 1.91 4.64 3.35 1.25 1.44 2.21 3.16 2.86 5.16.65 2 .98 4.19.98 6.56 0 2.33-.33 4.49-.98 6.49zm50.3 16.64c2.98-1.37 5.54-3.21 7.68-5.51 2.14-2.3 3.81-4.95 5.02-7.96 1.21-3 1.82-6.15 1.82-9.45 0-3.12-.59-6.18-1.75-9.21a26.494 26.494 0 00-4.88-8.09c-2.09-2.37-4.62-4.29-7.57-5.76-2.96-1.46-6.22-2.2-9.8-2.2-3.49 0-6.73.69-9.7 2.06-2.98 1.37-5.55 3.22-7.71 5.55-2.17 2.33-3.86 5-5.1 8.02a24.978 24.978 0 00-1.84 9.49c0 3.16.58 6.26 1.74 9.28 1.16 3.02 2.8 5.71 4.92 8.06 2.11 2.35 4.65 4.23 7.6 5.65 2.96 1.42 6.25 2.13 9.88 2.13 3.48 0 6.72-.69 9.69-2.06zM118.58-30.8c.62-1.98 1.54-3.75 2.75-5.31 1.21-1.55 2.72-2.81 4.54-3.76 1.81-.96 3.88-1.43 6.21-1.43 2.23 0 4.24.45 6.03 1.36 1.79.9 3.3 2.12 4.54 3.66 1.23 1.53 2.18 3.3 2.86 5.3.67 2 1.01 4.07 1.01 6.21 0 2.05-.32 4.06-.94 6.04-.63 1.97-1.56 3.75-2.79 5.33a14.16 14.16 0 01-4.54 3.81c-1.79.95-3.85 1.43-6.17 1.43-2.28 0-4.32-.47-6.11-1.4-1.79-.93-3.3-2.16-4.53-3.7-1.24-1.53-2.18-3.3-2.83-5.3-.65-2-.98-4.07-.98-6.21 0-2.04.32-4.06.95-6.03zM199.75 0v-8.44H174.5v-41.1h-9.63V0h34.88zm2.31 0h9.9l4.54-12.35h17.3L238.41 0h9.9l-18.83-49.54h-8.52L202.06 0zm16.04-19.12l7.12-19.46 6.84 19.46H218.1z"
        display="block"
        fontFamily="Manrope"
        fontSize="69.771"
        fontWeight="700"
        transform="matrix(3.96 0 0 3.96 695.176 372.793)"
      />
      <path
        fill="none"
        stroke="#4AC3CA"
        strokeMiterlimit="10"
        strokeWidth="2.966"
        d="M-60.391 27.756L60.391-27.756"
        display="block"
        transform="matrix(3.96 0 0 3.96 405.233 122.088)"
      />
      <path
        fill="none"
        stroke="#F76F4A"
        strokeMiterlimit="10"
        strokeWidth="9.167"
        d="M-2.429-38.241c-20.043 1.275-35.85 18.118-35.85 38.202 0 20.999 17.28 38.28 38.279 38.28 20.999 0 38.279-17.281 38.279-38.28a38.28 38.28 0 00-3.41-15.792l-19.77 9.545"
        display="block"
        transform="matrix(3.96 0 0 3.96 173.53 228.96)"
      />
      <path
        fill="none"
        stroke="#F76F4A"
        strokeMiterlimit="10"
        strokeWidth="9.167"
        d="M30.687 22.731c4.762-6.382 7.592-14.271 7.592-22.77a38.28 38.28 0 00-3.41-15.792l-19.77 9.545"
        display="block"
        transform="matrix(3.96 0 0 3.96 410.993 117.429)"
      />
      <g display="block">
        <path
          fill="#4AC3CA"
          d="M22.403-23.39c-.885-1.356-2.444-2.163-4.095-2.177l-14.013-.07h-.267l-3.736-.021-1.181-.014h-.14l-4.397-.029-13.654-.077c-2.002-.007-3.835 1.159-4.587 2.957-2.494 5.956-3.899 11.492-4.545 16.535a53.728 53.728 0 00-.442 5.914c0 .056-.014.105 0 .161-.014.077-.014.162-.014.239-.007 1.04.014 2.044.056 3.02.021.562.056 1.117.098 1.658 0 .056 0 .113.007.162 0 .07 0 .14.014.196.745 8.66 3.491 14.94 5.261 18.199.85 1.552 2.529 2.515 4.348 2.515h38.049c2.023 0 3.841-1.18 4.572-2.999 10.037-25.068 2.227-40.732-1.334-46.169z"
          transform="matrix(1.00002 0 0 1.00002 -114.008 -304.762) matrix(3.96 0 0 3.96 432.315 687.536)"
        />
        <path
          fill="#FFF"
          d="M11.809 5.696c0 1.714-1.145 3.091-2.571 3.091H-9.228c-1.25 0-2.248-1.046-2.508-2.402a3.561 3.561 0 01-.014-1.363c.464-2.508 1.208-5.879 2.241-8.871 1.011-2.894 3.371-4.776 5.991-4.797l9.279-.106 3.456-.035c.414 0 .822.119 1.166.323.035.028.084.049.119.084.407.267.737.66.962 1.138.218.464.345.984.345 1.553V5.696z"
          transform="matrix(1.00002 0 0 1.00002 -114.008 -304.762) matrix(3.96 0 0 3.96 381.978 632.657)"
        />
        <path
          fill="#FFF"
          d="M9.246 8.787h-18.48c-1.412 0-2.578-1.377-2.578-3.091V-5.689c0-.906.33-1.707.829-2.276.091-.091.183-.183.274-.267.225-.183.478-.338.759-.429.028-.014.056-.021.077-.028a.588.588 0 00.084-.028c.105-.021.218-.049.337-.056.077-.014.162-.014.253-.014l12.728.141c2.613.021 4.973 1.903 5.991 4.797 1.047 2.992 1.778 6.363 2.227 8.871.365 1.932-.857 3.765-2.501 3.765z"
          transform="matrix(1.00002 0 0 1.00002 -114.008 -304.762) matrix(3.96 0 0 3.96 483.497 632.657)"
        />
      </g>
      <g display="block">
        <path
          fill="#F76F4A"
          d="M22.403-23.39c-.885-1.356-2.444-2.163-4.095-2.177l-14.013-.07h-.267l-3.736-.021-1.181-.014h-.14l-4.397-.029-13.654-.077c-2.002-.007-3.835 1.159-4.587 2.957-2.494 5.956-3.899 11.492-4.545 16.535a53.728 53.728 0 00-.442 5.914c0 .056-.014.105 0 .161-.014.077-.014.162-.014.239-.007 1.04.014 2.044.056 3.02.021.562.056 1.117.098 1.658 0 .056 0 .113.007.162 0 .07 0 .14.014.196.745 8.66 3.491 14.94 5.261 18.199.85 1.552 2.529 2.515 4.348 2.515h38.049c2.023 0 3.841-1.18 4.572-2.999 10.037-25.068 2.227-40.732-1.334-46.169z"
          transform="matrix(1.00002 0 0 1.00002 -116.012 -308.01) matrix(3.96 0 0 3.96 671.778 579.746)"
        />
        <path
          fill="#FFF"
          d="M11.809 5.696c0 1.714-1.145 3.091-2.571 3.091H-9.228c-1.25 0-2.248-1.046-2.508-2.402a3.561 3.561 0 01-.014-1.363c.464-2.508 1.208-5.879 2.241-8.871 1.011-2.894 3.371-4.776 5.991-4.797l9.279-.106 3.456-.035c.414 0 .822.119 1.166.323.035.028.084.049.119.084.407.267.737.66.962 1.138.218.464.345.984.345 1.553V5.696z"
          transform="matrix(1.00002 0 0 1.00002 -116.012 -308.01) matrix(3.96 0 0 3.96 621.441 524.868)"
        />
        <path
          fill="#FFF"
          d="M9.246 8.787h-18.48c-1.412 0-2.578-1.377-2.578-3.091V-5.689c0-.906.33-1.707.829-2.276.091-.091.183-.183.274-.267.225-.183.478-.338.759-.429.028-.014.056-.021.077-.028a.588.588 0 00.084-.028c.105-.021.218-.049.337-.056.077-.014.162-.014.253-.014l12.728.141c2.613.021 4.973 1.903 5.991 4.797 1.047 2.992 1.778 6.363 2.227 8.871.365 1.932-.857 3.765-2.501 3.765z"
          transform="matrix(1.00002 0 0 1.00002 -116.012 -308.01) matrix(3.96 0 0 3.96 722.96 524.868)"
        />
      </g>
    </g>
  </svg>
);
