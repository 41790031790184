import { SVGProps } from 'react';

export const ProIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40.5 23"
    {...props}
  >
    <style>
      {
        '\n\t.st0{fill:url(#SVGID_1_);}\n\t.st1{fill:#FFFFFF;}\n'
      }
    </style>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={6.4944}
        y1={25.2211}
        x2={33.9839}
        y2={-2.2684}
      >
        <stop
          offset={0.3328}
          style={{
            stopColor: '#DAA76E',
          }}
        />
        <stop
          offset={0.7528}
          style={{
            stopColor: '#EDC679',
          }}
        />
      </linearGradient>
      <path
        className="st0"
        d="M7.2,23h26.1c4,0,7.2-3.2,7.2-7.2V7.2c0-4-3.2-7.2-7.2-7.2L7.2,0C3.2,0,0,3.2,0,7.2l0,8.6 C0,19.7,3.2,23,7.2,23z"
      />
    </g>
    <g>
      <g>
        <path
          className="st1"
          d="M24.6,10.3c-0.2-0.3-0.5-0.4-0.8-0.4l-2.7,0h0c0.3-0.8,0.4-1.7,0.4-2.6c0-0.9-0.1-1.7-0.4-2.5 C21,4.6,21,4.4,20.9,4.2c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1-0.2-0.2-0.3l-1.6,0.7l-3.1,1.4l0.2,0.5l0,0 L16,5.9l0.2,0.4l0.3,0.6l3-1.4c0,0,0,0,0,0c0.2,0.6,0.2,1.1,0.2,1.7c0,0.9-0.2,1.8-0.5,2.6l0,0l-2.7,0c-0.4,0-0.7,0.2-0.9,0.6 c-0.5,1.2-0.8,2.2-0.9,3.2c0,0,0,0,0,0c-0.1,0.4-0.1,0.8-0.1,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0 c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.1,1.7,0.7,2.9,1,3.5c0.2,0.3,0.5,0.5,0.8,0.5H24c0.4,0,0.7-0.2,0.9-0.6 C26.8,14.4,25.3,11.3,24.6,10.3z M19.6,13.9h-1.4c0,0,0,0,0,0H16c-0.2,0-0.4-0.2-0.5-0.5c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3 c0.1-0.5,0.2-1.1,0.4-1.7c0.2-0.6,0.7-0.9,1.2-0.9l2.5,0c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1 c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3v2.2h0C20.1,13.6,19.8,13.9,19.6,13.9z M24.6,13.9H21c-0.3,0-0.5-0.3-0.5-0.6v-2V11 c0-0.2,0.1-0.3,0.2-0.4c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,2.6,0,2.6,0c0.5,0,1,0.4,1.2,0.9 c0.2,0.6,0.3,1.2,0.4,1.7C25.1,13.5,24.9,13.9,24.6,13.9z"
        />
      </g>
    </g>
  </svg>
);
