import {
  createContext, useEffect, useState, FC, useContext,
} from 'react';
import { Auth, User, getAuth } from 'firebase/auth';
import { firebaseApp } from 'lib/firebase';

type ContextState = {
  auth: Auth;
  firebaseUser: User | null;
};

const FirebaseAuthContext = createContext<ContextState | undefined>(undefined);

const FirebaseAuthProvider: FC = ({ children }) => {
  const auth = getAuth(firebaseApp);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  const value = { firebaseUser, auth };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setFirebaseUser);
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

/**
 * Note that useFirebaseAuth() will not work inside a modal that is opened via
 * `mui-modal-provider` because the `mui-modal-provider` provider context is always outside the
 * FirebaseAuthProvider context. See `AddEmailModal` for an example of how to access the
 * FirebaseUser inside a modal opened via `mui-modal-provider`.
 */
function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseAuthProvider',
    );
  }
  return context;
}

export { FirebaseAuthProvider, useFirebaseAuth };
