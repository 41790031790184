import {
  Alert, AlertTitle, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { A } from 'components/A';
import { ErrorMsg } from 'lib/constants/errors';
import { instagramUrlToId } from './utils';

interface Props {
  url: string;
  message: string;
  onClose: () => void;
}

export const LinkTrackingAlert = ({
  url, message, onClose,
}: Props) => {
  const facebookText = (
    <>
      <AlertTitle>Facebook post</AlertTitle>
      <p className="mt-2">
        Importing individual Facebook posts by url is not supported.
      </p>
      <p className="mt-2">
        If you have access to the Facebook page, please
        {' '}
        <A href="/settings/accounts" className="underline">connect it</A>
        {' '}
        to your Gondola account and we can auto-import those posts.
        You can see all of your auto-imported Facebook posts in your
        {' '}
        <A href="/manager?creditedStatus=all&networks=facebook" className="underline">
          content manager
        </A>
        .
      </p>

    </>
  );

  const twitterText = (
    <p className="mt-2 font-semibold">
      We are currently experiencing issues importing tweets from X (aka Twitter).
      We are working to resolve as quickly as possible.
    </p>
  );

  const tiktokText = (
    <p className="mt-2 font-semibold">
      TikTok doesn&apos;t support slideshow analytics yet.
      Can you believe it??
      We saved the link you have submitted in case they change their minds!
    </p>
  );

  const twitchText = (
    <p className="mt-2 font-semibold">
      Gondola only supports importing Twitch videos and clips.
      Live streams are not yet supported,
      but you can add the video after the stream is complete.
      If you want to import your entire channel,
      go to
      {' '}
      <a href="/manager" className="underline">
        Content Manager
      </a>
      {' '}
      and click the [Add Posts] button.
    </p>
  );

  const privateInstagramText = (
    <p className="mt-2 font-semibold">
      This post may be from a private account. If you&apos;re the owner of this account,
      you&apos;ll need to make it public to track analytics and get credit!
    </p>
  );

  const addedToQueueText = (
    <>
      <AlertTitle>Added to queue</AlertTitle>
      <div>
        <p className="mt-2">
          It&apos;s taking a bit longer to track this post than usual.
          We have added it to an import queue.
        </p>
        <p className="mt-2">
          We&apos;ll send a notification when the post is imported.
        </p>
        {url.includes('twitter') && twitterText}
        {message.includes('slideshow') && tiktokText}
      </div>
    </>
  );

  const notSupportedText = (
    <>
      <AlertTitle>{ErrorMsg.PLATFORM_NOT_SUPPORTED}</AlertTitle>
      <p className="mt-2">
        We do not currently support the platform or url.
      </p>
    </>
  );

  const softDeleteText = (
    <>
      <AlertTitle>That post was marked as deleted by a Gondola user.</AlertTitle>
      <p className="mt-2">
        Please contact support@gondola.cc if you believe the post was mistakenly deleted.
      </p>
    </>
  );

  const threadsText = (
    <>
      <AlertTitle>Threads post</AlertTitle>
      <p className="mt-2">
        Good news! Gondola now supports importing Threads! However, we can only tracks posts
        that have been bulk uploaded from authed accounts. To start tracking your threads content:
        <br />
        1. Go to
        {' '}
        <a href="/settings/accounts" className="underline">
          My Accounts
        </a>
        {' '}
        and connect your Threads account, then
        <br />
        2. Go to
        {' '}
        <a href="/manager" className="underline">
          Content Manager
        </a>
        {' '}
        and click the [Add Posts] button to bulk import.
      </p>
    </>
  );

  const getContent = () => {
    if (url.includes('facebook') || url.includes('fb.watch')) return facebookText;

    if (url.includes('threads')) return threadsText;

    if (message.includes('Platform')) return notSupportedText;

    if (url.includes('twitch.tv')) return twitchText;

    if (url.includes('instagram')) {
      const id = instagramUrlToId(url);
      if (id && id.length > 20) {
        return privateInstagramText;
      }
    }

    if (message.includes('deleted')) return softDeleteText;

    return addedToQueueText;
  };

  return (
    <div className="max-w-lg z-6 mt-2 text-left">
      <Alert
        severity="warning"
        onClose={onClose}
        action={(
          <IconButton color="inherit" size="small" onClick={onClose}>
            <Close />
          </IconButton>
        )}
      >
        {getContent()}
      </Alert>
    </div>
  );
};
