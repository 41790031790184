import { webAssetUrl } from 'lib/utils/imageUtils';
import Head from 'next/head';
import { BlogStructuredData, JobStructuredData } from 'lib/types/structuredData';
import { isProd } from 'lib/helpers';

interface Props {
  title: string;
  description: string;
  url: string;
  image?: string;
  keywords?: string;
  robots?: string;
  cardUrl?: string;
  structuredData?: BlogStructuredData | JobStructuredData;
}

const DEFAULT_OG_CARD = webAssetUrl('meta-data-cards/home.png');
const DEFAULT_OG_TITLE = 'Gondola - One account for everything you make on social.';
const DEFAULT_TITLE = 'Gondola: Social Credits, Portfolio, and Analytics';

export const GondolaHead = ({
  title, image, description, url, keywords, robots, cardUrl, structuredData,
}: Props) => {
  const pageTitle = title && title !== 'Gondola' ? `${title} | Gondola` : DEFAULT_TITLE;
  const ogTitle = title && title !== 'Gondola' ? `${title} | Gondola` : DEFAULT_OG_TITLE;
  const imageUrl = cardUrl || image || DEFAULT_OG_CARD;

  return (
    <Head>
      <title key="title">
        {pageTitle}
      </title>
      <meta property="og:title" content={ogTitle} key="og:title" />
      <meta name="twitter:title" content={ogTitle} key="twitter:title" />
      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />

      {description && (
        <>
          <meta property="og:description" content={description} key="og:description" />
          <meta name="twitter:description" content={description} key="twitter:description" />
          <meta name="description" content={description} key="description" />
        </>
      )}
      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} key="og:image" />
          <meta name="twitter:image" content={imageUrl} key="twitter:image" />
        </>
      )}

      {url && (
        <>
          <meta property="og:url" content={url} key="og:url" />
          <link rel="canonical" href={url} key="canonical" />
        </>
      )}

      {keywords && (
        <meta name="keywords" content={keywords} key="keywords" />
      )}

      {robots && (
        <meta name="robots" content={robots} key="robots" />
      )}

      {!isProd() && (
        <meta name="robots" content="noindex, nofollow" key="robots" />
      )}

      {structuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      )}
      <meta name="facebook-domain-verification" content="3c4erowb4nqjxzhk9h5wypyrrw7yo5" />
      <meta name="apple-itunes-app" content="app-id=1633545646" />
    </Head>
  );
};

interface FragmentProps {
  title?: string;
  description?: string;
  url?: string;
}

export const GondolaHeadFragment = ({
  title, description, url,
}: FragmentProps) => {
  if (!title && !description && !url) {
    return null;
  }
  const pageTitle = title && title !== 'Gondola' ? `${title} | Gondola` : DEFAULT_TITLE;
  const ogTitle = title && title !== 'Gondola' ? `${title} | Gondola` : DEFAULT_OG_TITLE;

  return (
    <Head>
      {title
        && (
          <>
            <title key="title">
              {pageTitle}
            </title>
            <meta property="og:title" content={ogTitle} key="og:title" />
            <meta name="twitter:title" content={ogTitle} key="twitter:title" />
            <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
          </>
        )}

      {description && (
        <>
          <meta property="og:description" content={description} key="og:description" />
          <meta name="twitter:description" content={description} key="twitter:description" />
          <meta name="description" content={description} key="description" />
        </>
      )}
      {url && (
        <>
          <meta property="og:url" content={url} key="og:url" />
          <link rel="canonical" href={url} key="canonical" />
        </>
      )}
    </Head>
  );
};
