import {
  ReactNode, useCallback, useState,
} from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import {
  Close, MailOutline, Visibility, VisibilityOff,
} from '@mui/icons-material';
import {
  Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput,
} from '@mui/material';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useFirebaseAuth } from 'lib/contexts/firebaseAuthContext';
import { logFirebaseDebugInfo, signInWithEmail, signUpWithEmail } from 'lib/utils/firebaseAuthUtils';
import { getAuth } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { firebaseApp } from 'lib/firebase';
import { Emoji } from 'elements/Emoji';
import { TwitterSignIn } from '../TwitterSignIn';
import { GoogleSignInWrapper } from '../GoogleSignIn';

enum Step {
  Initial,
  SignIn,
  VerifyCode,
  SignUp,
}

interface Props {
  returnTo?: string;
  title?: string;
  onShow?: (s: boolean) => void;
  /**
   * If a user has verified their email address, the `verifiedEmail` prop is the verified
   * email. This allows us to skip to the step where the user sets their password.
   */
  verifiedEmail?: string;
}

export function EmailSignUp({
  returnTo, title, onShow, verifiedEmail,
}: Props) {
  return (
    <Base
      returnTo={returnTo}
      title={title || 'Sign up with Email'}
      onShow={onShow}
      verifiedEmail={verifiedEmail}
    />
  );
}

export function EmailSignIn({
  returnTo, title, onShow, verifiedEmail,
}: Props) {
  return (
    <Base
      returnTo={returnTo}
      title={title || 'Sign in with Email'}
      onShow={onShow}
      verifiedEmail={verifiedEmail}
    />
  );
}

const Base = ({
  returnTo, title, onShow, verifiedEmail,
}: Props) => {
  const api = useApi();
  const currentUser = useCurrentUser();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const to = (router.query.returnTo || returnTo || '/') as string;
  const { firebaseUser } = useFirebaseAuth();

  const [_verifiedEmail, setVerifiedEmail] = useState(verifiedEmail);
  const [show, setShow] = useState(!!_verifiedEmail);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(_verifiedEmail ? Step.SignUp : Step.Initial);

  const [verificationCode, setVerificationCode] = useState('');
  const [codeErr, setCodeErr] = useState<ReactNode>();

  const [email, setEmail] = useState(_verifiedEmail || '');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [emailErr, setEmailErr] = useState<ReactNode>();
  const [password1Err, setPassword1Err] = useState<ReactNode>();
  const [password2Err, setPassword2Err] = useState<ReactNode>();
  const [submitErr, setSubmitErr] = useState<ReactNode>();

  const [showMagicLink, setShowMagicLink] = useState(false);

  logFirebaseDebugInfo(currentUser, firebaseUser);
  const checkEmail = useCallback(() => {
    if (!email) {
      return true;
    }
    if (email.trim() === '') {
      setEmailErr('Email is required.');
      return true;
    }

    setEmailErr('');
    return false;
  }, [email]);

  const sendCode = async () => {
    try {
      await api.sendVerifyCode(email, to);
      enqueueSnackbar('Code sent! Please check your mailbox.', { variant: 'success' });
    } catch (err: any) {
      if (err.message.includes('email verification failed')) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Sending email verification failed. Please check your email address is correct and contact us if you are unable to receive the verification email.', { variant: 'error' });
      }
    }
  };

  const sendMagicLink = async () => {
    await api.sendMagicLinkEmail(email, to);
    enqueueSnackbar('Email sent! Please check your mailbox.', { variant: 'success' });
  };

  const checkCode = useCallback(() => {
    if (!verificationCode) {
      return true;
    }
    if (verificationCode.length < 6) {
      setCodeErr('Code cannot shorter than 6 characters.');
      return true;
    }

    setCodeErr('');
    return false;
  }, [verificationCode]);

  const checkPassword = useCallback((setError: (msg: string) => void, password?: string) => {
    if (!password) {
      return true;
    }
    if (password && password.length < 6) {
      setError('Password cannot be shorter than 6 characters.');
      return true;
    }

    if (password1 && password2 && password1 !== password2) {
      setError('Passwords do not match.');
      return true;
    }

    setError('');
    return false;
  }, [password1, password2]);

  const reset = useCallback((setTo?: boolean) => {
    setShow((flag) => {
      const b = typeof setTo === 'undefined' ? !flag : setTo;
      if (onShow) onShow(b);
      return b;
    });
    setVerifiedEmail(undefined);
    router.push({
      pathname: router.pathname,
      query: { ...router.query, returnTo: to },
    }, undefined, { shallow: false });

    setStep(Step.Initial);
    setLoading(false);
    setShowPassword(false);
    setEmail('');
    setPassword1('');
    setPassword2('');

    setEmailErr('');
    setPassword1Err('');
    setPassword2Err('');
    setSubmitErr('');

    setShowMagicLink(false);
    setVerificationCode('');
  }, [_verifiedEmail, onShow, router]);

  const onClearClick = useCallback(() => {
    reset(true);
  }, [reset]);

  const onSignUpComplete = useCallback(() => {
    if (router.query.welcome) {
      window.location.href = `/signup?returnTo=${to}&welcome=${router.query.welcome}`;
    } else {
      window.location.href = `/signup?returnTo=${to}`;
    }
  }, []);

  const onSignInComplete = useCallback(() => {
    window.location.href = to;
  }, [to]);

  const onToggleClick = useCallback(() => {
    reset();
  }, [reset]);

  const onFormSubmit = useCallback(async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);

      switch (step) {
      case Step.SignIn: {
        if (checkEmail() || checkPassword(setPassword1Err, password1)) {
          return;
        }

        const auth = getAuth(firebaseApp);

        try {
          await signInWithEmail(api, auth, email, password1);

          onSignInComplete();
        } catch (err: any) {
          const { code } = (err as FirebaseError);

          await api.trackEmailPwError(email, {
            code,
            message: err?.message,
          });
          if (code === 'auth/wrong-password') {
            setSubmitErr('Wrong email or password');
          } else if (code === 'auth/user-not-found') {
            setSubmitErr('User not found.  Please use sign up via the link below.');
          } else if (code === 'auth/invalid-email') {
            setSubmitErr('Invalid email');
          } else {
            setSubmitErr('Something went wrong.  Please try again later.');
          }
        }

        return;
      }
      case Step.VerifyCode: {
        if (checkEmail() || checkCode()) {
          return;
        }

        try {
          await api.verifyCode(email, verificationCode);
          setStep(Step.SignUp);
          setSubmitErr('');
        } catch (err: any) {
          if (err.message) {
            setSubmitErr(err.message);
          } else {
            setSubmitErr('Something went wrong.  Please try again later.');
          }
        }

        return;
      }
      case Step.SignUp: {
        if (
          checkEmail()
          || checkPassword(setPassword1Err, password1)
          || checkPassword(setPassword2Err, password2)) {
          return;
        }

        const auth = getAuth(firebaseApp);

        try {
          await signUpWithEmail(api, auth, email, password1, true);

          onSignUpComplete();
        } catch (error: any) {
          const { code } = (error as FirebaseError);
          await api.trackEmailPwError(email, { code, message: error?.message });

          let errMsg;
          if (code === 'auth/email-already-in-use') {
            try {
              await signInWithEmail(api, auth, email, password1);

              onSignUpComplete();
            } catch (err: any) {
              const signInCode = (err as FirebaseError)?.code;
              await api.trackEmailPwError(email, {
                context: 'auth/email-already-in-use',
                code: signInCode,
                message: err.message,
              });

              if (signInCode === 'auth/wrong-password') {
                setEmailErr(
                  <span>
                    You appear to be attempting to sign-in,
                    but that password does not match the email.
                  </span>,
                );
              } else if (err?.message?.match('No user with a matching credential')) {
                // This is an unusual case where the user entered a correct email/pw for Firebase,
                // but the Firebase UID does not match the email credential in our DB.
                // Likely an admin-only issue due to testing in different envs.
                errMsg = err.message;
              } else {
                errMsg = 'Something went wrong. Please try again later.';
              }
            }
          } else if (code === 'auth/invalid-email') {
            setEmailErr('Invalid email address');
            return;
          } else {
            errMsg = 'Something went wrong. Please try again later.';
          }
          setSubmitErr(errMsg);
        }

        return;
      }
      default: { // step === Step.Initial
        if (checkEmail()) {
          return;
        }

        const availableCredentials = await api.checkUncredentialedEmail(email);

        if (availableCredentials.hasTwitter && !availableCredentials.hasEmail) {
          setSubmitErr((
            <div className="text-black">
              That email is linked to a Gondola account that has signed in with Twitter.
              You can add an email/password login to your account once you sign in with Twitter.
              and you can add an email/password login.
              <div className="mt-2">
                <TwitterSignIn returnTo={to} text="Continue with Twitter" />
              </div>
            </div>));
        } else if (availableCredentials.hasGoogle && !availableCredentials.hasEmail) {
          setSubmitErr((
            <div className="text-black">
              <p>
                That email is linked to a Gondola account that has signed in with Google.
                You can add an email/password login once you sign in with Google.
              </p>
              <div className="mt-2"><GoogleSignInWrapper returnTo={to} text="Continue with Google" /></div>
            </div>));
        } else if (availableCredentials.hasEmail) {
          setSubmitErr(null);
          setShowMagicLink(availableCredentials.emailIsVerified);
          setStep(Step.SignIn);
        } else {
          setSubmitErr(null);
          await api.sendVerifyCode(email, to);
          setStep(Step.VerifyCode);
        }
      }
      }
    } catch (err: any) {
      if (err.message.includes('email verification failed')) {
        setSubmitErr(err.message);
      }
      setSubmitErr('Oops, something went wrong.');
    } finally {
      setLoading(false);
    }
  }, [
    api, checkEmail, checkPassword, email,
    onSignInComplete, onSignUpComplete,
    password1, password2, step,
    verificationCode, checkCode, to,
  ]);

  const actionButtonText = () => {
    if (step === Step.Initial) {
      return 'Next';
    }
    if (step === Step.VerifyCode) {
      return 'Verify Code';
    }
    if (step === Step.SignUp) {
      return 'Submit';
    }

    return 'Sign In';
  };

  const actionButtonDisabled = () => {
    if (!email || isLoading) {
      return true;
    }
    if (step === Step.SignIn && !password1) {
      return true;
    }
    if (step === Step.VerifyCode && !verificationCode) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={`mb-4 ${!show ? '' : 'hidden'}`}>
        <button
          type="button"
          className="btn-primary-outlined normal-case text-xl font-bold py-2 w-full max-w-xs"
          onClick={onToggleClick}
        >
          <MailOutline className="mr-2" />
          {title}
        </button>
      </div>
      <div data-testid="signin-signup-form" className={show ? '' : 'hidden'}>
        <div className="max-w-lg mx-auto">
          <div className="p-4">
            {step === Step.Initial && <h1 className="text-2xl font-semibold mb-4">Enter your email address</h1>}
            <form onSubmit={onFormSubmit}>
              <div className="mb-4 w-full">
                <FormControl fullWidth>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput
                    autoComplete="email"
                    autoFocus
                    disabled={step !== Step.Initial}
                    error={!!emailErr}
                    fullWidth
                    id="email"
                    inputMode="email"
                    label="Email"
                    name="email"
                    onBlur={checkEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    endAdornment={step !== Step.Initial && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear email"
                          onClick={onClearClick}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                          tabIndex={-1}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                  {emailErr && <div className="text-red mb-2">{emailErr}</div>}
                </FormControl>
              </div>

              {step === Step.VerifyCode && (
                <>
                  <div className="mb-2 w-full">
                    <h3>
                      <div className="mb-2">
                        <div>
                          <div>
                            Hi
                            {' '}
                            <Emoji emoji="👋" description="hand wave emoji" />
                            !
                          </div>
                          Check your inbox for an email from us.
                          To continue with signup, click the link in the email or copy the code
                          from the email into the box below.
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn-primary-outlined mb-2 text-xs"
                        onClick={sendCode}
                      >
                        Re-send verification email
                      </button>
                    </h3>
                  </div>
                  <div className="mb-4 w-full">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="code">Email verification code</InputLabel>
                      <OutlinedInput
                        error={!!codeErr}
                        fullWidth
                        id="code"
                        label="Code"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        type="text"
                        value={verificationCode}
                      />
                      {codeErr && <div className="text-red">{codeErr}</div>}
                    </FormControl>
                  </div>
                </>
              )}

              {step === Step.SignUp && (
                <>
                  <div className="mb-4 w-full">
                    <h3>
                      Great! Now choose a password for your account to continue.
                    </h3>
                  </div>
                  <div className="mb-4 w-full">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="pwd">Password</InputLabel>
                      <OutlinedInput
                        autoComplete="new-password"
                        autoFocus
                        error={!!password1Err}
                        fullWidth
                        id="pwd"
                        label="Password"
                        onBlur={() => checkPassword(setPassword1Err, password1)}
                        onChange={(e) => setPassword1(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        value={password1}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                      {password1Err && <div className="text-red">{password1Err}</div>}
                    </FormControl>
                  </div>
                  <div className="mb-4 w-full">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="rt-pwd">Retype Password</InputLabel>
                      <OutlinedInput
                        autoComplete="new-password"
                        error={!!password2Err}
                        fullWidth
                        id="rt-pwd"
                        label="Retype Password"
                        onBlur={() => checkPassword(setPassword2Err, password2)}
                        onChange={(e) => setPassword2(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        value={password2}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle retype password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                      {password2Err && <div className="text-red">{password2Err}</div>}
                    </FormControl>
                  </div>
                </>
              )}

              {step === Step.SignIn && (
                <>
                  <div className="mb-4 w-full">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="pwd">Password</InputLabel>
                      <OutlinedInput
                        autoComplete="new-password"
                        autoFocus
                        error={!!password1Err}
                        fullWidth
                        id="pwd"
                        label="Password"
                        onBlur={() => checkPassword(setPassword1Err, password1)}
                        onChange={(e) => setPassword1(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        value={password1}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                      {password1Err && <div className="text-red">{password1Err}</div>}
                    </FormControl>
                    {showMagicLink && (
                      <div className="mt-2">
                        <div>
                          Or send a magic link to your email to sign in immediately
                        </div>
                        <button
                          type="button"
                          className="btn-primary my-2"
                          onClick={sendMagicLink}
                        >
                          Send Magic Link
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}

              {submitErr && <div className="text-red mb-4">{submitErr}</div>}

              <div className="text-right">
                <span className="mr-2">
                  <Button
                    className="btn-primary"
                    disabled={actionButtonDisabled()}
                    type="submit"
                    variant="contained"
                  >
                    {actionButtonText()}
                  </Button>
                </span>
                <Button
                  variant="outlined"
                  onClick={onToggleClick}
                >
                  {step === Step.Initial ? 'Back' : 'Cancel'}
                </Button>
              </div>

              {step === Step.SignIn && (
                <div className="mt-4">
                  <Link href="/reset-password" className="underline">I forgot my password</Link>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
