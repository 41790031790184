import React from 'react';
import {
  Facebook, Instagram, YouTube, Link as LinkIcon, LinkedIn, X as XIcon,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { TikTokIcon } from './TikTokIcon';
import { BehanceIcon } from './BehanceIcon';
import { TwitchIcon } from './TwitchIcon';
import { ThreadsIcon } from './ThreadsIcon';

export const networkToColor = (network: number) => {
  if (network === 1) { // twitter
    return '#000000';
  } if (network === 2) { // 'instagram'
    return '#c32aa3';
  } if (network === 3) { // 'facebook'
    return '#3b5998';
  } if (network === 4) { // 'youtube'
    return '#ff0000';
  } if (network === 5) { // 'tiktok'
    return '#EE1D52';
  } if (network === 6) { // 'behance'
    return '#053eff';
  } if (network === 8) { // 'linkedin'
    return '#0077b5';
  } if (network === 9) { // 'twitch'
    return '#9146FF';
  }
  return '#393939';
};

interface Props extends SvgIconProps {
  network: number;
  showColor?: boolean;
}

export const NetworkIcon = ({
  network, showColor = false, style, ...props
}: Props) => {
  const color = showColor ? networkToColor(network) : '#393939';
  if (network === 1) {
    return <XIcon style={{ color, ...style }} {...props} />;
  } if (network === 2) {
    return <Instagram style={{ color, ...style }} {...props} />;
  } if (network === 3) {
    return <Facebook style={{ color, ...style }} {...props} />;
  } if (network === 4) {
    return <YouTube style={{ color, ...style }} {...props} />;
  } if (network === 5) {
    return <TikTokIcon style={{ color, ...style }} {...props} />;
  } if (network === 6) {
    return <BehanceIcon style={{ color, ...style }} {...props} />;
  } if (network === 8) {
    return <LinkedIn style={{ color, ...style }} {...props} />;
  } if (network === 7) {
    return <LinkIcon style={{ color, ...style }} {...props} />;
  } if (network === 9) {
    return <TwitchIcon style={{ color, ...style }} {...props} />;
  } if (network === 10) {
    return <ThreadsIcon style={{ color, ...style }} {...props} />;
  }
  return (<div />);
};
