import {
  createContext, ReactNode, useCallback, useEffect, useMemo, useContext, useReducer,
} from 'react';
import { User, UserState } from 'lib/types/users';
import { apiClient } from 'lib/apiClient';
import { noop } from 'lodash';
import { sendUserToApp } from 'lib/utils/mobileAppUtils';
import { trackUser } from 'lib/gtag';

interface Props {
  children: ReactNode;
}

interface ReducerState {
  api: ReturnType<typeof apiClient>;
  currentUser: UserState;
  /** 
   * Returns true if we have successfully checked to see if the current user is authed.
   **/
  isAuthed: boolean;
  teamId: number | null | undefined;
}

interface Action {
  type: 'SET' | 'UNSET';
  payload: ReducerState;
}

type State = ReducerState & {
  setCurrentUser: (user: User) => void;
  setTeamId: (teamId: number) => void;
}

const isSSR = typeof window === 'undefined';

const initialState = {
  api: apiClient(),
  currentUser: null,
  setCurrentUser: noop,
  isAuthed: false,
  teamId: null,
  setTeamId: noop,
};

export const ApplicationState = createContext<State>(initialState);

export function useApplicationState() {
  return useContext(ApplicationState);
}

export function useApi() {
  const { api } = useApplicationState();
  return api;
}

export function useCurrentUser() {
  const { currentUser } = useApplicationState();
  return currentUser;
}

export function useCurrentUserIsAuthed() {
  const { isAuthed } = useApplicationState();
  return isAuthed;
}

export function useCurrentTeamId() {
  const { teamId } = useApplicationState();
  return teamId;
}

function stateReducer(prevState: ReducerState, action: Action): ReducerState {
  switch (action.type) {
  case 'SET':
    return action.payload;
  case 'UNSET':
    return initialState;
  default:
    throw new Error('Invalid action.');
  }
}

export function ApplicationStateProvider({ children }: Props) {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const setCurrentUser = useCallback((currentUser) => dispatch({
    type: 'SET',
    payload: {
      api: apiClient(currentUser),
      currentUser,
      isAuthed: true,
      teamId: state.teamId || currentUser?.currentTeamId,
    },
  }), [dispatch, state]);

  const setTeamId = useCallback((teamId) => dispatch({
    type: 'SET',
    payload: {
      ...state,
      teamId,
    },
  }), [dispatch, state]);

  const providerValue = useMemo(() => ({
    ...state,
    setCurrentUser,
    setTeamId,
  }), [setCurrentUser, setTeamId, state]);

  useEffect(() => {
    const init = async () => {
      try {
        const currentUser = await initialState.api.getAuth({});

        dispatch({
          type: 'SET',
          payload: {
            api: apiClient(currentUser),
            currentUser,
            isAuthed: true,
            teamId: currentUser?.currentTeamId,
          },
        });

        if (currentUser) {
          sendUserToApp(currentUser);
          trackUser(currentUser);
        } else {
          // Sends an undefined user to the app, which sets it to logged-out state
          sendUserToApp();
        }
      } catch (err) {
        /* swallow */
      }
    };

    if (isSSR) {
      return;
    }

    init();
  }, []);

  return (
    <ApplicationState.Provider value={providerValue}>
      {children}
    </ApplicationState.Provider>
  );
}
