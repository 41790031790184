import { toNumber, isNaN } from 'lodash';

const NOT_A_DIGIT = /[^\d|.|-]/g;

/**
 * toNumberOrUndefined takes a value from scraping that is most likely
 * a number (but could be a string or something else), extracts the number
 * from it if possible, and returns the number OR undefined.
 * 
 * Rather than return 0 or anything that is not a number, it returns
 * undefined. The reason is that this will result in a post object that does
 * not include this undefined stat when we go to update the post. So if a post 
 * stops getting a stat for some reason we don't overwrite the non-zero stat with 0.
 * @param  {any} value
 */
export const toNumberOrUndefined = (value: any) => {
  let finalValue = value;
  if (typeof value === 'string') {
    finalValue = value.toString().replace(NOT_A_DIGIT, '');
  }
  const number = toNumber(finalValue);
  return isNaN(number) || !number ? undefined : Math.max(number, 0);
};

/**
 * Sleep the provided number of milliseconds.
 */
export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
