import { NextRouter, SingletonRouter } from 'next/router';
import {
  isLoggedIn, userIsAdmin, userIsBeta, userIsProOrAdmin,
} from 'lib/helpers';
import { UserState } from './types/users';
import { PERMISSIONS } from './constants';

export const goToLogin = (
  currentUser: UserState,
  router: SingletonRouter,
  returnTo: string,
) => {
  if (!isLoggedIn(currentUser)) {
    router.replace(`/signin?returnTo=${returnTo}`);
  }
};

export const isSignedUp = (currentUser: UserState) => (
  currentUser && currentUser.termsAcknowledged
);

const isOnboarded = (currentUser: UserState) => (
  currentUser && currentUser.isOnboarded
);

export const goToSignup = (
  currentUser: UserState,
  router: SingletonRouter,
) => {
  if (currentUser && !isSignedUp(currentUser)) {
    router.replace('/signup');
  }
};

export const goToNoPermission = (
  currentUser: UserState,
  permission: number,
  router: NextRouter,
) => {
  if (permission === PERMISSIONS.admin && !userIsAdmin(currentUser)) {
    router.replace('/404');
  }

  if (permission === PERMISSIONS.pro && !userIsProOrAdmin(currentUser)) {
    router.replace('/404');
  }

  if (permission === PERMISSIONS.beta && !(userIsAdmin(currentUser) || userIsBeta(currentUser))) {
    router.replace('/404');
  }
};

export const shouldCompleteSignup = (user: UserState) => {
  if (!user) {
    return false;
  }
  return !isSignedUp(user);
};

export const shouldOnboard = (user: UserState) => {
  if (!user) {
    return false;
  }
  return !isOnboarded(user);
};
