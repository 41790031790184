import React, { ReactNode, AnchorHTMLAttributes } from 'react';
import Link from 'next/link';

interface Props
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: ReactNode;
  className?: string;
}

export const A = ({
  href, children, className, ...restProps
}: Props) => {
  const isExternal = href.startsWith('http');
  let externalProps = {};
  const defaultCss = 'hover:underline';
  const css = className ? `${defaultCss} ${className}` : defaultCss;

  if (isExternal) {
    externalProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };

    return (
      <a
        className={css}
        href={href}
        {...externalProps}
        {...restProps}
      >
        {children}
      </a>
    );
  }

  return (
    <Link href={href} passHref className={css} {...restProps}>
      {children}
    </Link>
  );
};
