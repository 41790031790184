import { User } from './types/users';

export const GA_TRACKING_ID = 'G-LZY9M3SKTN';

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// TODO(Jordan): We will probably use this at some point
// export const event = ({
//   action, category, label, value,
// }) => {
//   window.gtag('event', action, {
//     event_category: category,
//     event_label: label,
//     value,
//   });
// };

type EventName = 'clickButton' | 'profileMenu' | 'matchPost'

export const trackClick = (eventName: EventName, eventData: any) => {
  try {
    window.gtag('event', eventName, eventData);
  } catch (err) {
    // NOOP
  }
};

export const pageTitle = () => window?.document?.title || '';

export const trackPageView = (url: string) => {
  try {
    window.gtag('config', GA_TRACKING_ID, {
      page_title: pageTitle(),
      page_path: url,
      page_location: url,
    });
  } catch (error) {
    // silences the error in dev mode
    // and/or if gtag fails to load
  }
};

/**
 * trackUser sends some info about the current user to Google Analytics
 * @param  {User} user
 */
export const trackUser = (user: User) => {
  try {
    window.gtag('config', GA_TRACKING_ID, {
      user_id: user.id,
    });
    window.gtag('set', 'user_properties', {
      is_pro: user.isPro,
    });
  } catch (err) {
  // Ignore
  }
};
