import { Slide, useMediaQuery, useScrollTrigger } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

export const HideOnScroll = ({ children }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const trigger = useScrollTrigger();

  // No need to hide on desktop
  if (!isMobile) return children;

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
