// Utils used for to support the WebView-based mobile app

import { User } from 'lib/types/users';
import { pageTitle } from 'lib/gtag';

type MessageType = 'user' | 'page' | 'auth';

type PageStatus = 'start' | 'complete';

interface PageMessageData {
  url: string;
  title: string;
  status: PageStatus;
}

interface AuthMessageData {
  type: string;
  url?: string;
}

interface AppMessage {
  data: User | PageMessageData | AuthMessageData;
  type: MessageType;
}

/** APP_URI_SCHEMA is the uri schema of the react-native app
 * This can be used for branching flows when the react-native app is used
 */

export const APP_URI_SCHEMA = 'com.gondola.ios';

/** APP_VERSION is the version gondola react-native app as determined by the userAgent
 * This is used to allow for branching flows based on the react-native app version used
 */

export const APP_VERSION: any = global?.navigator?.userAgent.match(/GondolaApp\/(\d+\.\d+\.\d+)/)?.[1] || '';

/** isGondolaApp helps determine if the client is the gondola react-native app
 * This can be used for branching flows when the react-native app is used
 */

export const isGondolaApp: boolean = /GondolaApp/.test(global?.navigator?.userAgent) || false;

/** isGondolaAppVersionAtLeast checks version of react native webview user agent.
 * This is used to allow for branching flows based on the react-native app version used
 * @param  {string} versionToCheck
 */

export function isGondolaAppVersionAtLeast(versionToCheck: string) {
  if (!isGondolaApp || !APP_VERSION || !versionToCheck) {
    return false;
  }

  if (APP_VERSION === versionToCheck) {
    return true;
  }

  const versionToCheckSplit = versionToCheck.split('.').map(Number);
  const isNewer = APP_VERSION.split('.').map(Number).some((i: number, idx: number) => i > versionToCheckSplit[idx]);

  return isNewer;
}

/** sendAuthRequestToApp sends an auth request to the react native webview.
 * This is used to allow the app to determine how to handle a specific auth method
 * @param  {string} type
 * @param  {string} url
 */

export function sendAuthRequestToApp(type: string, url?: string) {
  if (!isGondolaApp) {
    return;
  }

  try {
    const authMessage: AppMessage = {
      type: 'auth',
      data: { type, url },
    };
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(authMessage));
  } catch (err: any) {
    // noop
  }
}

/** sendUserToApp sends the user to a react native webview.
 * This is used to track the current user in the app.
 * @param  {User} user
 */

export const sendUserToApp = (user?: User) => {
  if (!isGondolaApp) {
    return;
  }

  try {
    const userObj = user || {};
    const userMessage: AppMessage = {
      type: 'user',
      data: userObj as User,
      // TODO(Jordan): Don't send the user like this after updating the app
      ...userObj,
    };
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(userMessage));
  } catch (err: any) {
    // noop
  }
};

/** sendPageToApp sends the current page to a react native webview.
 * This is used to track page changes in the app.
 * The status is used by the mobile app to determine if the page is starting
 * or ending its load.
 * @param  {string} url
 * @param  {PageStatus} status
 */
export const sendPageToApp = (url: string, status: PageStatus) => {
  if (!isGondolaApp) {
    return;
  }

  try {
    const title: string = pageTitle() || url;

    const pageMessage: AppMessage = {
      type: 'page',
      data: {
        status,
        url,
        title,
      },
    };
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(pageMessage));
  } catch (err: any) {
    // noop
  }
};
