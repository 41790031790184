import { ReactNode, useState } from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { FirebaseError } from 'firebase/app';
import {
  getAuth, GoogleAuthProvider, OAuthCredential,
} from 'firebase/auth';
import { useApplicationState } from 'lib/contexts/ApplicationState';
import { firebaseApp } from 'lib/firebase';
import { isGondolaApp, isGondolaAppVersionAtLeast, sendAuthRequestToApp } from 'lib/utils/mobileAppUtils';
import GoogleIcon from '@mui/icons-material/Google';
import { StaleEmailLoginError, logFirebaseDebugInfo, signInWithGoogle } from 'lib/utils/firebaseAuthUtils';
import { useFirebaseAuth } from 'lib/contexts/firebaseAuthContext';
import { useRouter } from 'next/router';

interface Props {
  returnTo?: string;
  text?: string;
}

export const GoogleSignIn = ({ returnTo, text }: Props) => {
  const [errMsg, setErrMsg] = useState<ReactNode>('');
  const router = useRouter();
  const { api, currentUser } = useApplicationState();
  const auth = getAuth(firebaseApp);
  const to = returnTo || '/';
  const { firebaseUser } = useFirebaseAuth();
  logFirebaseDebugInfo(currentUser, firebaseUser);
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse) => {
      let credential: OAuthCredential | undefined;

      try {
        const response = await api.exchangeTokens(tokenResponse.code);

        if (!response) {
          throw new Error('Did not receive an tokens needed to sign user into Gondola.');
        }
        const { idToken, authStatus } = response;
        if (!idToken) {
          throw new Error('Did not receive an idToken needed to sign user into Gondola.');
        }

        if (authStatus === 'email-credential' && !firebaseUser && !currentUser?.id) {
          setErrMsg(
            <span className="text-black">
              That email is linked to a Gondola account that has signed in with a password.
              <button
                className="mx-1 underline"
                type="button"
                onClick={() => router.push({
                  pathname: '/signin',
                  query: { w: 'email', returnTo: '/settings/login' },
                })}
              >
                Sign in with email/password
              </button>
              and you can add a Google login.
            </span>,
          );
          return;
        }
        if (authStatus === 'email-credential' && !firebaseUser && !currentUser?.id) {
          setErrMsg(
            <span className="text-black">
              That email is linked to a Gondola account that has signed in with a password.
              Please sign out of Gondola and sign in with email/password using
              <button
                className="mx-1 underline"
                type="button"
                onClick={() => router.push({
                  pathname: '/signin',
                  query: {
                    w: 'email',
                    returnTo: '/settings/login',
                  },
                })}
              >
                this link
              </button>
              in order to add a Google login to your account.
            </span>,
          );
          return;
        }

        credential = GoogleAuthProvider.credential(idToken);
        await signInWithGoogle(api, auth, credential, authStatus);
        window.location.href = to;
      } catch (err: FirebaseError | any) {
        await api.trackGoogleAuthError({
          providerId: credential?.providerId,
          message: err?.message,
          code: err?.code,
          errorCode: err?.errorCode,
        });
        if (err.message.match('matching email credential')) {
          setErrMsg(err.message);
          return;
        }
        const { errorCode } = (err as StaleEmailLoginError);
        if (errorCode === 'stale-email-login') {
          setErrMsg(err.message);
          return;
        }

        const { code } = (err as FirebaseError);
        if (code !== 'auth/popup-closed-by-user') {
          setErrMsg('Something went wrong.  Please try again later.');
        }
      }
    },
  });

  const signIn = async () => {
    // if app is used divert request and return out of normal flow
    if (isGondolaApp) {
      if (isGondolaAppVersionAtLeast('1.0.8')) {
        sendAuthRequestToApp('google');
        return;
      }

      // TODO: remove this alert for mobile users when enough have upgraded
      // eslint-disable-next-line no-alert
      alert('Login with Google is supported on the latest version of the Gondola app.');
      return;
    }

    login();
  };

  return (
    <>
      <button
        className="bg-white hover:bg-gray-100 border-mediumgray border rounded-lg text-mediumgray text-xl font-bold py-2 w-full max-w-xs"
        onClick={signIn}
        type="button"
      >
        <div className="flex justify-center gap-2">
          <GoogleIcon />
          {text || 'Continue with Google'}
        </div>
      </button>
      <div className="text-red">{errMsg}</div>
    </>
  );
};

export const GoogleSignInWrapper = ({ returnTo, text }: Props) => (
  <GoogleOAuthProvider clientId="1097728377800-bq2ep1rm20qtnqbch2cc9hj33s4eoqn7.apps.googleusercontent.com">
    <div className="mb-4">
      <GoogleSignIn returnTo={returnTo} text={text} />
    </div>
  </GoogleOAuthProvider>
);
