import { ReactElement } from 'react';
import { PrimaryAppBar } from 'components/AppBar';
import { Footer } from 'components/Footer';
import { GondolaHead } from 'components/GondolaHead/GondolaHead';
import { isGondolaApp } from 'lib/utils/mobileAppUtils';

interface Props {
  children: ReactElement;
}

export const Layout = ({ children }: Props) => {
  // Since the back button is at the bottom, and the horizontal "grab bar"
  // is present on some devices, it's good to have a little extra space
  // at the bottom of the mobile app so any content doesn't get blocked
  // when you scroll all the way down.
  const renderExtraSpaceForApp = <div className="pt-24" />;

  return (
    <>
      <div className="w-full relative flex" style={{ minHeight: 'calc(100vh - 112px)' }}>
        <GondolaHead
          title="Gondola: Social Credits, Portfolio, and Analytics"
          description="Gondola gives you the power to collect, organize, and track social media posts and give credit to the great minds that make them."
          url="https://gondola.cc"
        />
        <PrimaryAppBar />
        <div className="w-full pt-14">
          {children}
        </div>
      </div>
      {!isGondolaApp ? <Footer /> : renderExtraSpaceForApp}
    </>
  );
};
