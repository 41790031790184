import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/router';
import { TwitterSignIn } from 'features/SignIn/TwitterSignIn';
import { useModal } from 'mui-modal-provider';
import { EmailSignIn } from 'features/SignIn/EmailAuth';
import Link from 'next/link';
import { GoogleSignInWrapper } from 'features/SignIn/GoogleSignIn';
import { FirebaseAuthProvider } from 'lib/contexts/firebaseAuthContext';

interface Props {
  text: string;
  onCancel: () => void;
}

export const SignUpModal = ({ text, onCancel }: Props) => {
  const router = useRouter();
  return (
    <Dialog open>
      <div className="lg:min-w-[480px] p-8 text-center">
        <h2 className="font-bold text-orange text-xl">
          <button className="absolute right-4 top-4 text-gray-500" type="button" aria-label="close" onClick={onCancel}>
            <CloseIcon />
          </button>
        </h2>
        <div>
          <p className="pb-4">{text}</p>
          <div className="pb-4">
            <TwitterSignIn returnTo={router.asPath} />
          </div>
          <FirebaseAuthProvider>
            <GoogleSignInWrapper returnTo={router.asPath} />
            <div className="pb-4">
              <EmailSignIn returnTo={router.asPath} />
            </div>
          </FirebaseAuthProvider>
          <div className="text-lg pb-4">
            <span className="font-semibold">Don&apos;t have a Gondola account?</span>
            {' '}
            <Link href={{ pathname: '/signup', query: { returnTo: router.asPath } }} className="underline">

              Sign Up (Free!)

            </Link>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const useSignUpModal = () => {
  const { showModal } = useModal();

  const showSignUpModal = (text?: string) => {
    const modal = showModal(SignUpModal, {
      text: text || 'Please sign up or sign in to do that!',
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  return { showSignUpModal };
};
