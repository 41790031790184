import { NetworkIcon } from 'elements/NetworkIcon';
import { NETWORK_IDS } from 'lib/constants';
import { isGondolaApp, isGondolaAppVersionAtLeast, sendAuthRequestToApp } from 'lib/utils/mobileAppUtils';
import { omitBy } from 'lodash';
import { SignInOptions } from './types';

interface Props {
  returnTo?: string;
  text?: string;
  /** The username the user has specified for signup */
  username?: string;
}

/**
 * Use this whenever you need a direct link to Twitter for signin. This handles app-specific
 * logic, requesting a specific username, and specifying a return-to path.
 */
export const onClickTwitterSignin = (options: SignInOptions) => {
  const baseUrl = '/api/auth/twitter';

  // if app is used divert request and return out of normal redirect flow
  if (isGondolaApp && isGondolaAppVersionAtLeast('1.0.8')) {
    sendAuthRequestToApp('twitter', baseUrl);
    return;
  }

  const allParams = {
    next: options.returnTo || '/',
    username: options.username,
    errorTo: options.errorTo,
  };
  const params = omitBy(allParams, (v) => !v) as Record<string, string>;
  const authUrl = `${baseUrl}?${new URLSearchParams(params).toString()}`;

  window.location.href = authUrl;
};

export const TwitterSignIn = ({ returnTo, text, username }: Props) => {
  const onClick = () => onClickTwitterSignin({ returnTo, username });

  return (
    <button
      type="button"
      className="rounded-lg bg-twitter hover:bg-twitterHover font-bold text-white text-xl w-full max-w-xs py-2"
      onClick={onClick}
    >
      <NetworkIcon
        style={{
          color: 'white',
          marginRight: '8px',
          verticalAlign: 'middle',
        }}
        network={NETWORK_IDS.twitter}
      />
      {text || 'Continue with Twitter'}
    </button>
  );
};
