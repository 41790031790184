import { GoogleAuthStatus } from 'contentAPIs/google/types';
import { FirebaseError } from 'firebase/app';
import {
  Auth,
  GoogleAuthProvider,
  OAuthCredential,
  createUserWithEmailAndPassword,
  signInWithCredential,
  signInWithEmailAndPassword,
  linkWithCredential,
  UserCredential,
  User,
} from 'firebase/auth';
import { apiClient } from 'lib/apiClient';
import { userIsAdmin } from 'lib/helpers';
import { UserState } from 'lib/types/users';

/**
 * StaleLoginError means that the user needs to login via email
 * in order to connect their Google account
 */
export class StaleEmailLoginError extends Error {
  errorCode: string = 'stale-email-login';
}

/**
 * signInWithEmail makes a request to Firebase to get a Firebase credential,
 * and then uses that credential to sign in the current user.
 * 
 * Do not catch errors in this function! The caller should catch errors from Firebase and
 * from the Gondola API and handle.
 */
export async function signInWithEmail(
  api: ReturnType<typeof apiClient>, auth: Auth, email: string, pwd: string,
) {
  const credential = await signInWithEmailAndPassword(auth, email, pwd);
  if (!credential) {
    throw new Error('Could not sign in user. No credential found.');
  }
  if (credential) {
    const { user } = credential;
    const token = await user.getIdToken();

    await api.signInWithEmail(
      {
        email,
        isEmailVerified: user.emailVerified,
        firebaseUid: user.uid,
        token,
      },
    );
  }
}

/**
 * signUpWithEmail makes a request to Firebase to create a Firebase user/credential,
 * and then uses that credential to sign up (or create an email credential for) the current user.
 * 
 * Do not catch errors in this function! The caller should catch errors from Firebase and
 * from the Gondola API and handle.
 */
export async function signUpWithEmail(
  api: ReturnType<typeof apiClient>,
  auth: Auth,
  email: string,
  pwd: string,
  isEmailVerified = false,
) {
  const credential = await createUserWithEmailAndPassword(auth, email, pwd);
  if (!credential) {
    throw new Error('Could not sign in user. No credential found.');
  }
  const { user } = credential;
  const token = await user.getIdToken();

  await api.signUpWithEmail(
    {
      email,
      isEmailVerified: isEmailVerified || user.emailVerified,
      firebaseUid: user.uid,
      token,
    },
  );
}

/**
 * signInWithGoogle makes a request to Firebase to create a Firebase user/credential,
 * and then uses that credential to sign up (or create a Google credential for) the current user.
 *
 * If the user already has a Firebase email credential, link the user's existing email credential in
 * Firebase to the newly-creaed Google credential.
 *
 * Do not catch errors in this function unless there is a specific case to handle in here!
 * The caller should catch more errors from Firebase and from the Gondola API and handle.
 */
export async function signInWithGoogle(
  api: ReturnType<typeof apiClient>,
  auth: Auth,
  credential: OAuthCredential,
  authStatus: GoogleAuthStatus,
) {
  const firebaseUser = auth.currentUser;
  let userCredential: UserCredential;
  if (authStatus === 'email-credential') {
    if (!firebaseUser) {
      throw new StaleEmailLoginError('Please logout and sign-in to Gondola again using your email/password and visit the login settings to add a Google login.');
    }
    try {
      userCredential = await linkWithCredential(firebaseUser, credential);
    } catch (err) {
      const { code } = (err as FirebaseError);
      if (code === 'auth/provider-already-linked') {
        userCredential = await signInWithCredential(auth, credential);
        const oAuthCredential = GoogleAuthProvider.credentialFromResult(userCredential);
        if (!oAuthCredential?.idToken) {
          throw new Error('No idToken found.');
        }
        await api.signInWithGoogle({ idToken: oAuthCredential?.idToken });
        return;
      }
      throw err;
    }
  } else {
    userCredential = await signInWithCredential(auth, credential);
  }

  if (!userCredential) {
    throw new Error('Could not sign in user. No credential found.');
  }
  const oAuthCredential = GoogleAuthProvider.credentialFromResult(userCredential);
  if (!oAuthCredential?.idToken) {
    throw new Error('No idToken found.');
  }
  await api.signInWithGoogle({ idToken: oAuthCredential?.idToken });
}

/**
 * If the current user is an admin (via Gondola session) log the user's Firebase session info.
 * 
 * Otherwise just return.
 */
export function logFirebaseDebugInfo(currentUser: UserState, firebaseUser: User | null) {
  if (!userIsAdmin(currentUser)) {
    return;
  }
  if (firebaseUser?.email) {
    const providers = firebaseUser.providerData.map((d) => d.providerId);
    console.debug(`Logged into Firebase as ${firebaseUser?.email} via ${providers.join(', ')}`);
  }
}
