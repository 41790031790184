import { useState, MouseEvent } from 'react';
import { Popover } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GlobalSearch } from 'components/GlobalSearch';

export const Search = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button type="button" onClick={onClick} className="hover:bg-hintgray rounded-full p-2">
        <SearchIcon />
      </button>
      <Popover
        id="golbal-search"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className="px-4 py-2 w-full min-w-[300px]">
          <GlobalSearch
            label="Track or Search"
            onSearch={onClose}
          />
        </div>
      </Popover>
    </>
  );
};
