import { useRedirectToSignup } from 'lib/hooks/useRedirectToSignup';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

/**
   * On every page (except /signup), check if the user should be redirected to /signup
   * to complete the signup process
  */
export const RedirectToSignup = ({ children }: Props) => {
  useRedirectToSignup();

  return (
    <>
      {children}
    </>
  );
};
