import Honeybadger from '@honeybadger-io/js';

export function notify(error: Error, component: string, context: any) {
  try {
    Honeybadger.notify(error, {
      component,
      context,
    });
  } catch (err) {
    console.error('Error notifying Honeybadger', err);
  }
}
