import { EventRounded, LocationOnRounded, InsertDriveFileRounded } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { HashtagIcon } from 'elements/HashtagIcon';

interface Props extends SvgIconProps {
  tagTypeId: number;
}

export const TagValueIcon = ({ tagTypeId, ...props }: Props) => {
  switch (tagTypeId) {
  case 1:
    return (<EventRounded {...props} />);
  case 4:
    return (<InsertDriveFileRounded {...props} />);
  case 44:
    return (<LocationOnRounded {...props} />);
  default:
    return (<HashtagIcon {...props} />);
  }
};
