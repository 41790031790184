import { findKey, get } from 'lodash';
import { PostingAccountType } from './types/postingAccounts';

export type NetworkName = 'twitter' | 'instagram' | 'facebook' | 'youtube' | 'tiktok' | 'behance' | 'personal' | 'linkedin' | 'twitch' | 'threads';

export const CDN_HOST = 'https://cdn.gondola.cc';

export interface Network {
  title: string;
  name: NetworkName;
  userUrl: string;
  id: number;

  /** Does the network use an @ sign in the profile url? e.g. youtube.com/@myusername */
  usesAtSign: boolean;

  profileBaseUrl?: string;
}

export const NETWORKS: { [id: string]: Network } = {
  1: {
    title: 'Twitter',
    name: 'twitter',
    userUrl: 'twitterUrl',
    id: 1,
    usesAtSign: false,
    profileBaseUrl: 'https://twitter.com/',
  },
  2: {
    title: 'Instagram',
    name: 'instagram',
    userUrl: 'instagramUrl',
    id: 2,
    usesAtSign: false,
    profileBaseUrl: 'https://instagram.com/',

  },
  3: {
    title: 'Facebook',
    name: 'facebook',
    userUrl: 'facebookUrl',
    id: 3,
    usesAtSign: false,
    profileBaseUrl: 'https://facebook.com/',
  },
  4: {
    title: 'YouTube',
    name: 'youtube',
    userUrl: 'youtubeUrl',
    id: 4,
    usesAtSign: true,
    profileBaseUrl: 'https://youtube.com/',
  },
  5: {
    title: 'TikTok',
    name: 'tiktok',
    userUrl: 'tiktokUrl',
    id: 5,
    usesAtSign: true,
    profileBaseUrl: 'https://tiktok.com/',
  },
  6: {
    title: 'Behance',
    name: 'behance',
    userUrl: 'behanceUrl',
    id: 6,
    usesAtSign: false,
    profileBaseUrl: 'https://behance.com/',
  },
  7: {
    title: 'Personal website',
    name: 'personal',
    userUrl: 'personalUrl',
    id: 7,
    usesAtSign: false,
  },
  8: {
    title: 'LinkedIn',
    name: 'linkedin',
    userUrl: 'linkedinUrl',
    id: 8,
    usesAtSign: false,
    profileBaseUrl: 'https://linkedin.com/in/',
  },
  9: {
    title: 'Twitch',
    name: 'twitch',
    userUrl: 'twitchUrl',
    id: 9,
    usesAtSign: false,
    profileBaseUrl: 'https://twitch.tv/',
  },
  10: {
    title: 'Threads',
    name: 'threads',
    userUrl: 'threadsUrl',
    id: 10,
    usesAtSign: true,
    profileBaseUrl: 'https://threads.net/',
  },
};

export const NETWORK_IDS: Record<NetworkName, number> = {
  twitter: 1,
  instagram: 2,
  facebook: 3,
  youtube: 4,
  tiktok: 5,
  behance: 6,
  personal: 7,
  linkedin: 8,
  twitch: 9,
  threads: 10,
};

export const NETWORK_DETAILS: { [key: string]: Network } = {
  twitter: NETWORKS[NETWORK_IDS.twitter],
  instagram: NETWORKS[NETWORK_IDS.instagram],
  facebook: NETWORKS[NETWORK_IDS.facebook],
  youtube: NETWORKS[NETWORK_IDS.youtube],
  tiktok: NETWORKS[NETWORK_IDS.tiktok],
  behance: NETWORKS[NETWORK_IDS.behance],
  personal: NETWORKS[NETWORK_IDS.personal],
  linkedin: NETWORKS[NETWORK_IDS.linkedin],
  twitch: NETWORKS[NETWORK_IDS.twitch],
  threads: NETWORKS[NETWORK_IDS.threads],
};

// Default for permissions that never expire
export const DISTANT_FUTURE = '2500-12-31T12:00:00';

export const PERMISSIONS = {
  approved: 1,
  admin: 2,
  pro: 3,
  beta: 4,
  freePro: 5,
  enterprisePro: 6, // deprecated
  teamPro: 7,
};

// PRO and Team PRO permissions cannot be set manually in admin
export const READ_ONLY_PERMISSIONS = [
  PERMISSIONS.teamPro,
  PERMISSIONS.pro,
];

export const ProPermissions = [
  PERMISSIONS.pro,
  PERMISSIONS.freePro,
  PERMISSIONS.teamPro,
];

/**
 * Labels used in the UI for each permission level.
 * Note that enterprise PRO was deleted (it had been 6)
 **/
export const PERMISSION_LABELS: { [key: number]: string } = {
  1: 'O.G.', // original beta users, approved by Jared, get some PRO perks
  2: 'Admin', // admins, people who work at Gondola
  3: 'PRO', // Users with a paid PRO account
  4: 'Beta', // Users with beta access to new features
  5: 'Free PRO', // Users with a free pro account
  7: 'Team PRO', // Users part of a team that includes pro
};

export const getPermissionLabel = (permissionValue: number) => get(
  PERMISSION_LABELS, `${permissionValue}`, null,
);

interface UserTypeConfig {
  key: string;
  title: string;
  query: { [userType: string]: string };
  id: number;
}

export const USER_TYPES: Record<string, UserTypeConfig> = {
  creator: {
    key: 'creator',
    title: 'Creators',
    query: {
      userType: 'creator',
    },
    id: 2,
  },
  company: {
    key: 'company',
    title: 'Companies',
    query: {
      userType: 'company',
    },
    id: 1,
  },
  all: {
    key: 'all',
    title: 'All',
    query: {
      userType: 'all',
    },
    id: 0,
  },
};

export const USER_TYPE_IDS: Record<number, string> = {
  1: 'Company',
  2: 'Creator',
};

export const USER_TYPE_ARRAY = [
  { id: 1, name: 'Company' },
  { id: 2, name: 'Creator' },
];

export const TAG_TYPE_IDS = {
  event: 1,
  client: 2,
  creator: 3,
  filetype: 4,
  tag: 5,
  location: 44,
};

export const IDS_TO_TAG_TYPE: Record<number, string> = {
  1: 'event',
  2: 'client',
  3: 'creator',
  4: 'filetype',
  5: 'tag',
  44: 'location',
};

interface TagTypeConfig {
  id: number;
  name: string;
}

export const TAG_TYPES: { [key: string]: TagTypeConfig } = {
  event: {
    id: TAG_TYPE_IDS.event,
    name: 'event',
  },
  creator: {
    id: TAG_TYPE_IDS.creator,
    name: 'creator',
  },
  filetype: {
    id: TAG_TYPE_IDS.filetype,
    name: 'filetype',
  },
  tag: {
    id: TAG_TYPE_IDS.tag,
    name: 'tag',
  },
  location: {
    id: TAG_TYPE_IDS.location,
    name: 'Location',
  },
};

export const POST_TYPE_OBJECTS: { [key: string]: { title: string; id: number } } = {
  VIDEO: {
    title: 'Video',
    id: 1,
  },
  GIF: {
    title: 'Gif',
    id: 2,
  },
  IMAGE: {
    title: 'Image',
    id: 3,
  },
  TEXT: {
    title: 'Text',
    id: 4,
  },
};

export const POST_TYPES = {
  VIDEO: 1,
  GIF: 2,
  IMAGE: 3,
  TEXT: 4,
};

export const POST_TYPE_TEXT: { [id: number]: string } = {
  1: 'Video',
  2: 'Gif',
  3: 'Image',
  4: 'Text',
};

export const AUDIT_ACTIONS = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  read: 'read',
  list: 'list',
  login: 'login',
  logout: 'logout',
};

export const DISPUTED_CREDIT_STATUS = {
  open: 1,
  closed: 0,
};

export const getTagTypeName = (tagTypeId: number) => findKey(
  TAG_TYPE_IDS, (val) => val === tagTypeId,
);

export const DO_SPACES_URL = 'https://freshtapemedia.nyc3.digitaloceanspaces.com';

export const FB_ID = '1160211394336335';

export const FB_CLIENT_TOKEN = '163063607ae6942ccbaf2653ab1e14cb';

export const FILE_SIZE_LIMIT = 1024 * 1024 * 2; // 2mb
export const FIVE_MB_FILE_SIZE = 1024 * 1024 * 5; // 5mb
export const TWENTY_MB_FILE_SIZE = 1024 * 1024 * 20; // 20mb for zips

export const roleNameToEmoji: {[roleName: string]: string} = {
  Director: '🎬',
  'Director of Photography': '🎥',
  Copywriter: '🖊',
  'Writer/Author': '🖊',
  Editor: '✂️',
  Cinematographer: '🎥',
  Photographer: '📷',
  Videographer: '🎥',
  'Camera Operator': '🎥',
  'Drone Pilot': '🎮',
  Illustrator: '🖌',
  Animator: '🎞',
  Actor: '🎭',
  Actress: '🎭',
  Talent: '🎭',
  'Audio Designer': '🔈',
  'Graphic Artist': '🖌',
  'Stock Photography': '📷',
  Athlete: '👟',
  'Music Editor': '🎼',
  Makeup: '💄',
  'Boom Operator': '🎤',
};

// Used as a return value from getServerSideProps when we need to redirect to the 404 page
export const redirectTo404 = ({
  redirect: {
    destination: '/404',
    permanent: false,
  },
});

export const QUOTES: { [key: number]: string } = {
  1175: 'Something like this to manage all my content calls to me.',
  1385: `There is work I've made that I don't always post to my socials and this is a perfect way to
    still showcase/receive credit!`,
  12: `Everything I've ever made is in one spot. I seriously would never have the time to find and track
  my work without Gondola.`,
  458: 'Gondola is the one-stop shop for tracking ALL your content across MULTIPLE platforms. It\'s that simple.',
  15139: 'Gondola is a great place to see what you and your community have built together, all in one place!',
};

export const TWITCH_USER = 15139;

export const STAT_TEXT: { [key:string]: string } = {
  totalPosts: 'posts',
  totalViews: 'views',
  totalCombinedViews: 'views',
  totalLikes: 'likes',
  totalShares: 'shares',
  totalComments: 'comments',
};

export const NO_YOUTUBE_EMBED = ['nfl', 'nfl rush', 'los angeles chargers'];

export const GONDOLA_USER = { id: 227 };

export const ADMIN_USER = { id: -1, isAdmin: true, username: '_admin_' };

export const ORDERED_STATS_TOTAL = ['totalPosts', 'totalCombinedViews', 'totalLikes', 'totalShares', 'totalComments'];

export const MILESTONE_TYPES = ['posts', 'views', 'likes', 'days', 'weeks'];

// For autocompletes, the amount of downtime needed to fire another request, in ms.
export const DEBOUNCE_TIME = 400;

// Default distance for near queries, in miles
export const DEFAULT_DISTANCE = 100;

export const CREATOR_DEFAULT_DISTANCE = 25;

// For setting session expiration
export const THREE_MONTHS = 1000 * 60 * 60 * 24 * 90;

export const FAVORITES_LIST_NAME = 'Favorites';

export const POSTING_ACCOUNT_TYPE: Record<string, PostingAccountType> = {
  personal: 'owner',
  owner: 'owner',
  manager: 'manager',
  managed: 'manager',
};
