import { createTheme } from '@mui/material/styles';

export const gondolaBreakpoints = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 900,
  xl: 1280,
  xxl: 1536,
};

export const theme = createTheme({
  palette: {
    primary: {
      light: '#ff9975',
      main: '#F76F4A',
      dark: '#A43313',
      contrastText: '#fff',
    },
    secondary: {
      light: '#bdffff',
      main: '#4AC3CA',
      dark: '#0E8C8D',
    },
    info: {
      main: '#EAEAEA',
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: 'inherit',
  },
  breakpoints: {
    values: {
      xs: gondolaBreakpoints.xs,
      sm: gondolaBreakpoints.sm,
      md: gondolaBreakpoints.md,
      lg: gondolaBreakpoints.lg,
      xl: gondolaBreakpoints.xl,
    },
  },
});
