import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { shouldCompleteSignup } from 'lib/redirect';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * Redirects the user to the signup page if they need to complete their signup.
 */
export const useRedirectToSignup = () => {
  const router = useRouter();
  const currentUser = useCurrentUser();
  useEffect(() => {
    // If the user is already on signup, or the user is signing out, there is nothing to do.
    if (router.pathname.match(/\/(signup|signout)/)) {
      return;
    }

    if (shouldCompleteSignup(currentUser)) {
      const { returnTo } = router.query;
      router.push({ pathname: '/signup', query: { returnTo: returnTo || router.asPath } });
    }
  }, [router, currentUser]);
};
