import { initializeApp } from 'firebase/app';

export const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBov3Ig0EbTSM-R4ZDAz7Y9Zttivcedb3g',
  authDomain: 'auth.gondola.cc',
  projectId: 'admin-218821',
  storageBucket: 'admin-218821.appspot.com',
  messagingSenderId: '1097728377800',
  appId: '1:1097728377800:web:61a8df6e9a399b81e676fd',
});
